<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap26BasicHttpServer"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 26: Basic HTTP Server" image-name="http-server.jpg" image-alt="Basic HTTP Server"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is an HTTP Server?</p></li>
<li><p>What is an HTTP Client?</p></li>
<li><p>What is HTML, and how to create an HTML file?</p></li>
<li><p>What are HTTP and IP?</p></li>
<li><p>How an HTTP request is formed</p></li>
<li><p>How an HTTP response is formed</p></li>
<li><p>How to build a basic webserver.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>HTTP</p></li>
<li><p>HTML</p></li>
<li><p>Request, response</p></li>
<li><p>Client, Server</p></li>
<li><p>DNS</p></li>
<li><p>Handler</p></li>
<li><p>Interface implementation</p></li>
</ul>
<div id="what-is-behind-a-web-page" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is behind a web page <a href="#what-is-behind-a-web-page"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>On figure <a href="#fig:A-simple-webpage-screen" data-reference-type="ref" data-reference="fig:A-simple-webpage-screen">1</a> you can see a very simple web page.</p>
<figure>
<b-img :src="require('@/assets/images/simple_web_page.png')" alt="A simple web page[fig:A-simple-webpage-screen]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A simple web page<span id="fig:A-simple-webpage-screen" label="fig:A-simple-webpage-screen">[fig:A-simple-webpage-screen]</span></figcaption>
</figure>
<p>What is behind that? The web browser will load source code. This source code is then transformed into what you see on the screen. Here is the source code that generates the page in figure <a href="#fig:A-simple-webpage-screen" data-reference-type="ref" data-reference="fig:A-simple-webpage-screen">1</a>:</p>
<pre v-highlightjs><code class="go" v-pre >&lt;!DOCTYPE html&gt;
&lt;html&gt;
  &lt;head&gt;
    &lt;title&gt;Title of page&lt;/title&gt;
  &lt;/head&gt;
  &lt;body&gt;
    &lt;h1&gt;Title&lt;/h1&gt;
    &lt;p&gt;Welcome to my website&lt;/p&gt;
    &lt;!-- comment, ignored by the browser --&gt;
  &lt;/body&gt;
&lt;/html&gt;</code></pre>
<p>This is HTML. HTML means Hypertext Markup Language. It’s not a programming language but a <strong>markup</strong> language.</p>
<p>With HTML, you can annotate a document to warn the web browser to apply a specific format to your content.</p>
<div id="html-basics" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> HTML basics <a href="#html-basics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s detail the code of the first example :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;!DOCTYPE html&gt;
&lt;html&gt;

&lt;/html&gt;</code></pre>
<ul>
<li><p>First, you can note that the document begins by <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;!DOCTYPE html&gt;</code></span>. This line tells the web browser that what follows is written using HTML.</p></li>
<li><p>The document is composed of elements. Each element begins with a <strong>start tag</strong> and ends with an <strong>end tag</strong>.</p></li>
<li><p>Elements can be nested. I can put one element inside another element.</p></li>
<li><p>The first element begins with the start tag <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;html&gt;</code></span> and ends with the end tag <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;/html&gt;</code></span>. It says between the start and the end tag; we will have other HTML elements.</p></li>
<li><p>Inside this first element, we have another element :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >&lt;head&gt;
    &lt;title&gt;Title of page&lt;/title&gt;
&lt;/head&gt;</code></pre>
<ul>
<li><p>Start tag is <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;head&gt;</code></span> and end tag is <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;/head&gt;</code></span>. This is the head section. Inside it, we will find metadata about the document. Metadata is information related to the document.</p>
<ul>
<li>Inside it, there is another element. It’s the title of the page. The title of the page will be displayed inside the browser tab.</li>
</ul></li>
<li><p>The next element is the body. It is the contents of the document: what will be displayed on the browser!</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >&lt;body&gt;
    &lt;h1&gt;Title&lt;/h1&gt;
    &lt;p&gt;Welcome to my website&lt;/p&gt;
    &lt;!-- comment, ignored by the browser --&gt;
&lt;/body&gt;</code></pre>
<ul>
<li><p>In the body, we have two elements and one comment</p>
<ul>
<li><p>The first element is a heading of level 1: <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;h1&gt;Title&lt;/h1&gt;</code></span>.</p></li>
<li><p>A paragraph: <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;p&gt;Welcome to my website&lt;/p&gt;</code></span>.</p></li>
<li><p>A comment <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;!-- comment, ignored by the browser --&gt;</code></span></p></li>
</ul></li>
<li><p>On the rendered web page, you will not see the tags. The web browser uses tags to render the page.</p></li>
</ul>
<div id="summary" class="anchor"></div>
<h4 data-number="4.0.0.1"><span class="header-section-number">4.0.0.1</span> Summary : <a href="#summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>With HTML, you can build web pages.</p></li>
<li><p>Elements begin with a start tag and an end tag.</p></li>
<li><p>Tags denote elements and are interpreted by the browser.</p></li>
<li><p>Elements can be nested.</p></li>
<li><p>Here is the skeleton of a simple web page :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >&lt;!DOCTYPE html&gt;
&lt;html&gt;
  &lt;head&gt;
    &lt;title&gt;Title of page&lt;/title&gt;
  &lt;/head&gt;
  &lt;body&gt;
    &lt;!-- Your content here --&gt;
  &lt;/body&gt;
&lt;/html&gt;</code></pre>
<div id="what-is-http-secwhat-is-http" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> What is HTTP ?<span id="sec:What-is-HTTP" label="sec:What-is-HTTP">[sec:What-is-HTTP]</span> <a href="#what-is-http-secwhat-is-http"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>HTTP means Hypertext Transfer Protocol. HTTP is a <strong>communication protocol</strong>. It is a set of specifications that describe how two parties can communicate.</p>
<p>In our everyday life, we use communication protocols: when you have a conversation with another human, there are some implied rules to follow. When you meet, you greet each other. Then the conversation starts. The conversation is sequential. The first interlocutor speaks, then the other one speaks. The end of the dialog is often punctuated with a “Goodbye” or “See you”.</p>
<p>HTTP is a protocol designed to make machines exchange information over a network. HTTP exists in 2 main versions HTTP/1.1 and HTTP/2. The two specifications are dense. We will focus on some key elements that you must know in the next sections :</p>
<div id="the-journey-of-an-http-request" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> The journey of an HTTP request <a href="#the-journey-of-an-http-request"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When you type in an <strong>URL</strong> inside your web browser and type enter an <strong>HTTP request</strong> is sent on the network.</p>
<p>An HTTP request is a message sent by a sender (the client) to a recipient (the server).</p>
<p>Servers are identified on the network by a unique name. This is the <strong>domain name</strong>. You can also send a request to a server by providing an <strong>IP address</strong>. When you make an HTTP request, you need to provide an IP address or a domain name for the network to route your request to the correct server.</p>
<figure>
<b-img :src="require('@/assets/images/request.png')" alt="Sequence diagram of an HTTP request[fig:Sequence-diagram-of-request]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Sequence diagram of an HTTP request<span id="fig:Sequence-diagram-of-request" label="fig:Sequence-diagram-of-request">[fig:Sequence-diagram-of-request]</span></figcaption>
</figure>
<p>Let’s define some important terms :</p>
<ul>
<li><p><strong>IP</strong> : <strong>I</strong>nternet <strong>P</strong>rotocol. This a communication protocol between computers on a network and between different networks. You use this protocol when you browse the web.</p></li>
<li><p>An <strong>IP address</strong> is a string composed of numbers separated by points (.) or colons (:). It is attributed to each device connected to a network that uses the Internet Protocol for communication.<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.<span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1</code></span> is an IP address. <span v-highlightjs><code class="go" v-pre style="display: inline">::1</code></span> is also an IP address. Note the difference between those two examples. The first one is an IPv4 address the second one is an IPv6 address.</p></li>
<li><p>A <strong>domain name</strong> is a string that identifies a server (or several servers) on the network. Domain names are managed by a complex but clever system named the <strong>Domain Name System</strong> (DNS).</p></li>
</ul>
<p>An HTTP server is a computer connected to the network thanks to the Internet Protocol. This computer runs a program that will handle HTTP requests sent to it by clients. Servers are rarely turned off. We will build a server!</p>
<div id="anatomy-of-an-http-request" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Anatomy of an HTTP request <a href="#anatomy-of-an-http-request"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an HTTP request (version 1.1 of the protocol). An HTTP request is a succession of lines sent on the network.</p>
<pre v-highlightjs><code class="go" v-pre >GET /pub/WWW/TheProject.html HTTP/1.1
Host: www.w3.org
User-Agent: curl/7.54.0
Accept: */*</code></pre>
<p>On figure <a href="#fig:Anatomy-of-HTTP-request" data-reference-type="ref" data-reference="fig:Anatomy-of-HTTP-request">3</a> you can see a detailed schema of a request :</p>
<figure>
<b-img :src="require('@/assets/images/Http_request_anatomy.png')" alt="Anatomy of an HTTP request[fig:Anatomy-of-HTTP-request]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Anatomy of an HTTP request<span id="fig:Anatomy-of-HTTP-request" label="fig:Anatomy-of-HTTP-request">[fig:Anatomy-of-HTTP-request]</span></figcaption>
</figure>
<div id="building-blocks-of-a-request" class="anchor"></div>
<h4 data-number="7.0.0.1"><span class="header-section-number">7.0.0.1</span> Building blocks of a request <a href="#building-blocks-of-a-request"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The request is composed of three blocks :</p>
<ol type="1">
<li><p>The request line</p></li>
<li><p>The headers</p></li>
<li><p>The message body</p></li>
</ol>
<div id="vocabulary" class="anchor"></div>
<h4 data-number="7.0.0.2"><span class="header-section-number">7.0.0.2</span> Vocabulary <a href="#vocabulary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p><strong>URI</strong> : <strong>U</strong>nified <strong>R</strong>essource <strong>I</strong>dentifier. Here : <span v-highlightjs><code class="go" v-pre style="display: inline">/pub/WWW/TheProject.html</code></span></p>
<ul>
<li>This is the identification of the resource (here an HTML page) that we want to retrieve</li>
</ul></li>
<li><p><strong>URL</strong> means “Uniform Ressource Locator”. Here it’s <span v-highlightjs><code class="go" v-pre style="display: inline">http://www.w3.org/pub/WWW/TheProject.html</code></span></p>
<ul>
<li><p>It identifies the resource</p></li>
<li><p>And indicates how the resource can be retrieved (make an HTTP request to the host www.w3.org)</p></li>
</ul></li>
<li><p>Each HTTP request has a <strong>method</strong>. Possible standard values are : <span v-highlightjs><code class="go" v-pre style="display: inline">OPTIONS, GET, HEAD, POST, PUT, DELETE, TRACE, CONNECT</code></span>.</p>
<ul>
<li>The web browser sends an HTTP <span v-highlightjs><code class="go" v-pre style="display: inline">GET</code></span> request when a new page is loaded</li>
</ul></li>
<li><p>The client may add <strong>headers</strong> to the requests. Headers are a way for “the client to pass to the server information abou t the request and about itself.”<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>.</p>
<ul>
<li><p>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">Host: www.w3.org</code></span> : this header indicates the network location of the requested resource.</p></li>
<li><p>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">Accept: */*</code></span> : the client says to the client that he can accept any media type: JSON, XML ...</p></li>
<li><p>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">User-Agent: curl/7.54.0</code></span> : information about the program that has issued the request</p></li>
</ul></li>
<li><p>The <strong>message body</strong> is the last part of the request. In the previous example, it’s empty. It is used to carry information.</p></li>
</ul>
<div id="anatomy-of-an-http-response" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Anatomy of an HTTP response <a href="#anatomy-of-an-http-response"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an HTTP response :</p>
<pre v-highlightjs><code class="go" v-pre >HTTP/1.1 404 Not Found
Content-Type: text/plain; charset=utf-8
Date: Fri, 03 Apr 2020 06:26:25 GMT
Content-Length: 19

404 page not found</code></pre>
<p>On figure <a href="#fig:Anatomy-of-a-http-response" data-reference-type="ref" data-reference="fig:Anatomy-of-a-http-response">4</a> you can see how the response is structured :</p>
<figure>
<b-img :src="require('@/assets/images/Http_response_anatomy.png')" alt="Anatomy of an HTTP response[fig:Anatomy-of-a-http-response]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Anatomy of an HTTP response<span id="fig:Anatomy-of-a-http-response" label="fig:Anatomy-of-a-http-response">[fig:Anatomy-of-a-http-response]</span></figcaption>
</figure>
<div id="building-blocks-of-a-response" class="anchor"></div>
<h4 data-number="8.0.0.1"><span class="header-section-number">8.0.0.1</span> Building blocks of a response <a href="#building-blocks-of-a-response"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The response is composed of three blocks :</p>
<ol type="1">
<li><p>The status line</p></li>
<li><p>The headers</p></li>
<li><p>The message body</p></li>
</ol>
<div id="vocabulary-1" class="anchor"></div>
<h4 data-number="8.0.0.2"><span class="header-section-number">8.0.0.2</span> Vocabulary <a href="#vocabulary-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The <strong>status line</strong> is the first line of the response</p>
<ul>
<li><p>It informs about the version of the protocol used</p></li>
<li><p>The status of the request indicated by a <strong>code</strong> and a <strong>reason phrase.</strong></p></li>
</ul></li>
<li><p><strong>Response headers</strong> “allow the server to pass additional information about the response which cannot be placed in the Status-Line”<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a></p>
<ul>
<li><p>The header <strong>Content-Type</strong> informs the client about the media type of the content and eventually the character set</p></li>
<li><p><strong>Content-Length</strong> : informs the client about the size of the message body</p></li>
</ul></li>
<li><p>The <strong>message body</strong> will contain the information requested.</p>
<ul>
<li><p>For a website, the message body will contain an HTML page.</p></li>
<li><p>If it’s an HTML page, the header <span v-highlightjs><code class="go" v-pre style="display: inline">Content-Type</code></span> will be equal to <span v-highlightjs><code class="go" v-pre style="display: inline">text/html</code></span></p></li>
</ul></li>
</ul>
<div id="status-code-of-an-http-response" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Status code of an HTTP response <a href="#status-code-of-an-http-response"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>An HTTP response will be composed of a status code. The status code is an integer number. You need to know the most common ones :</p>
<ul>
<li><p>Status Code <strong>200</strong> OK: the request was successfully treated by the server</p></li>
<li><p>Status Code <strong>400</strong> Bad Request: the request sent by the client is erroneous (malformed request, syntax error...)</p></li>
<li><p>Status Code <strong>404</strong> Not found: the server did not find the resource requested by the client.</p></li>
<li><p>Status Code <strong>500</strong> Internal Server Error : “the server encountered an unexpected condition that prevented it from fulfilling the request.”<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></p></li>
</ul>
<p>Other codes are available. You can find a list here https://tools.ietf.org/html/rfc7231#section-6.1.</p>
<p>One thing to remember is that the first number of the status code indicates the status code class. Five classes exist (see figure) :</p>
<figure>
<b-img :src="require('@/assets/images/http_status_code.png')" alt="The five classes of status codes"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The five classes of status codes</figcaption>
</figure>
<div id="how-to-build-a-simple-http-server" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> How to build a simple HTTP Server <a href="#how-to-build-a-simple-http-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can find all you need to build a web server in the standard library. The functions are available in the net/http package :</p>
<pre v-highlightjs><code class="go" v-pre >// basic-http-server/first/main.go
package main

import (
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    // create a server
    myServer := &amp;http.Server{
        // set the server address
        Addr: &quot;127.0.0.1:8080&quot;,
        // define some specific configuration
        ReadTimeout:  10 * time.Second,
        WriteTimeout: 10 * time.Second,
    }
    // launch the server
    log.Fatal(myServer.ListenAndServe())
}</code></pre>
<div id="code-explanations" class="anchor"></div>
<h4 data-number="10.0.0.1"><span class="header-section-number">10.0.0.1</span> Code explanations <a href="#code-explanations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We first begin by creating a variable <span v-highlightjs><code class="go" v-pre style="display: inline">myServer</code></span> which is a pointer to a new <span v-highlightjs><code class="go" v-pre style="display: inline">http.Server</code></span> struct.The struct <span v-highlightjs><code class="go" v-pre style="display: inline">http.Server</code></span> allow you to define a specific configuration for your server : <span v-highlightjs><code class="go" v-pre style="display: inline">Addr</code></span> : the address on which the server will listen for requests. In my example, I set it to <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8080</code></span>. This means that clients can send requests to the address <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8080</code></span>.</p>
<ul>
<li><p>Note that the address is composed of two parts : <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">8080</code></span> separated by a colon. The first part is the IP address (IPv4) the second is a port number.</p>
<ul>
<li>The <strong>port number</strong> complete the IP address. It’s an unsigned integer (16 bits). The operating system behind the Go program will direct received packets to this Go program that listens on these specific ports. On a single machine, you can run multiple servers with the same address IP and a different port. (<span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8081</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8083</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8084</code></span>)</li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ReadTimeout</code></span> : the maximum amount of time given to the web server to read the client’s request.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">WriteTimeout</code></span> : this is the maximum duration allowed to the server handlers to write the response. The last line of the program needs some explanations :</p>
<pre v-highlightjs><code class="go" v-pre >log.Fatal(myServer.ListenAndServe())</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">myServer.ListenAndServe()</code></span> is a method call that will launch the server. This method would return an error if something went wrong. For example, you will receive an error if the port number is already in use. <u>This function will run as long as the server run</u>. When the server does not run anymore, the method returns an error, which is passed to <span v-highlightjs><code class="go" v-pre style="display: inline">log.Fatal</code></span>.</p>
<div id="build-and-run" class="anchor"></div>
<h4 data-number="10.0.0.2"><span class="header-section-number">10.0.0.2</span> Build and run <a href="#build-and-run"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s build and run our brand new server :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main</code></pre>
<p>The program will run till an error occurred. Servers may run for months and even years without the need to be restarted.</p>
<p>Let’s test our server. We have two options :</p>
<ol type="1">
<li><p>With a web browser (Chrome)</p></li>
<li><p>With the command-line tool cURL</p></li>
</ol>
<div id="how-to-test-a-local-web-server-with-chrome" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> How to test a local web server with Chrome <a href="#how-to-test-a-local-web-server-with-chrome"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The first option is easy. Open your browser and type <span v-highlightjs><code class="go" v-pre style="display: inline">127.0.0.1:8080</code></span>.</p>
<figure>
<b-img :src="require('@/assets/images/test_local_server.png')" alt="Test on a web browser"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Test on a web browser</figcaption>
</figure>
<p>Our server answered “404 page not found” ! It answered something!</p>
<p>You have the ability with Chrome to inspect the request that was sent to your server. To do that, open the developer panel (see figure <a href="#fig:Chrome-open-dev-tools" data-reference-type="ref" data-reference="fig:Chrome-open-dev-tools">5</a>)</p>
<figure>
<b-img :src="require('@/assets/images/open_dev_tools_chrome.png')" alt="Chrome how to open the developer tools[fig:Chrome-open-dev-tools]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Chrome how to open the developer tools<span id="fig:Chrome-open-dev-tools" label="fig:Chrome-open-dev-tools">[fig:Chrome-open-dev-tools]</span></figcaption>
</figure>
<p>A window will appear (see figure <a href="#fig:Chrome-developer-tools" data-reference-type="ref" data-reference="fig:Chrome-developer-tools">6</a>). You then need to click on the “<strong>Network</strong>” tab.</p>
<figure>
<b-img :src="require('@/assets/images/dev_tools_opened_chrome.png')" alt="Chrome developer tools[fig:Chrome-developer-tools]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Chrome developer tools<span id="fig:Chrome-developer-tools" label="fig:Chrome-developer-tools">[fig:Chrome-developer-tools]</span></figcaption>
</figure>
<p>The network pane will record any network usage. For the moment, it’s empty because there is no network activity. Reload the page, and you will see the request made by Chrome (see figure<a href="#fig:View-of-the-request-404" data-reference-type="ref" data-reference="fig:View-of-the-request-404">7</a>)</p>
<figure>
<b-img :src="require('@/assets/images/dev_tool_request_get_404.png')" alt="View of the request in Chrome dev tools[fig:View-of-the-request-404]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">View of the request in Chrome dev tools<span id="fig:View-of-the-request-404" label="fig:View-of-the-request-404">[fig:View-of-the-request-404]</span></figcaption>
</figure>
<p>You can see :</p>
<ul>
<li><p>The name of the request</p></li>
<li><p>The method (HTTP method)</p></li>
<li><p>The request and the response header.</p></li>
<li><p>The status (here: 404 Not Found)</p></li>
<li><p>It’s type, the initiator, its size, and the time.</p></li>
</ul>
<p>You can get more information about the request and the response of our server by clicking on the request :</p>
<figure>
<b-img :src="require('@/assets/images/dev_tool_request_headers.png')" alt="Dev tools : request headers[fig:Dev-tools-headers]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dev tools : request headers<span id="fig:Dev-tools-headers" label="fig:Dev-tools-headers">[fig:Dev-tools-headers]</span></figcaption>
</figure>
<p>The tab “<strong>Headers</strong>” (see figure <a href="#fig:Dev-tools-headers" data-reference-type="ref" data-reference="fig:Dev-tools-headers">8</a>) contains the request and the response headers.</p>
<figure>
<b-img :src="require('@/assets/images/dev_tool_request_response.png')" alt="Response in Chrome Dev Tools[fig:Response-in-Chrome]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Response in Chrome Dev Tools<span id="fig:Response-in-Chrome" label="fig:Response-in-Chrome">[fig:Response-in-Chrome]</span></figcaption>
</figure>
<p>The tab “<strong>Response</strong>” print the server response. This is the raw response received from the server.</p>
<div id="how-to-test-a-local-web-server-with-curl" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> How to test a local web server with cURL? <a href="#how-to-test-a-local-web-server-with-curl"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>cURL is a software written in C released in 1997. This software is composed of two distinct elements: a library (<strong>libcurl</strong>) and a command-line interface (<strong>curl</strong>). We can use the library in a C program or in any other programs that provide bindings for it.</p>
<p>We will use the command-line tool to test our server.</p>
<p>The following command will make an HTTP GET request to the server address<span v-highlightjs><code class="go" v-pre style="display: inline">http://127.0.0.1:8080</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >$ curl http://127.0.0.1:8080 --verbose</code></pre>
<figure>
<b-img :src="require('@/assets/images/curl_decomposition.png')" alt="Decomposition of a curl command line output[fig:curl-output]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Decomposition of a curl command line output<span id="fig:curl-output" label="fig:curl-output">[fig:curl-output]</span></figcaption>
</figure>
<p>On figure <a href="#fig:curl-output" data-reference-type="ref" data-reference="fig:curl-output">10</a> you can see an example output of the command line. I have added the verbose flag intentionally to get more information about the request and the response. We can see :</p>
<ul>
<li><p>The connection to the server</p></li>
<li><p>The request that has been sent</p></li>
<li><p>The response received from the server</p></li>
<li><p>The final status of the connexion</p></li>
</ul>
<div id="how-to-serve-an-html-page-with-a-go-server" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> How to serve an HTML page with a Go Server? <a href="#how-to-serve-an-html-page-with-a-go-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The first server that we built does nothing: it receives an HTTP Response with a status code 404 (Not Found) for every HTTP request. We will send an HTML document in the message body of the response.</p>
<div id="http-response-with-html-contents" class="anchor"></div>
<h4 data-number="13.0.0.1"><span class="header-section-number">13.0.0.1</span> HTTP response with html contents <a href="#http-response-with-html-contents"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Our objective is to send this HTML document to the client :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;html&gt;
    &lt;head&gt;&lt;/head&gt;
    &lt;body&gt;Hello&lt;/hello&gt;
&lt;/html&gt;</code></pre>
<p>We need to be able to send this HTTP request with our server :</p>
<pre v-highlightjs><code class="go" v-pre >HTTP/1.1 200 OK
Content-Type: text/html; charset=utf-8
Date: Fri, 03 Apr 2020 06:26:25 GMT
Content-Length: 45

&lt;html&gt;
    &lt;head&gt;&lt;/head&gt;
    &lt;body&gt;Hello&lt;/hello&gt;
&lt;/html&gt;</code></pre>
<div id="the-server-type-struct" class="anchor"></div>
<h4 data-number="13.0.0.2"><span class="header-section-number">13.0.0.2</span> The Server type struct <a href="#the-server-type-struct"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">Server</code></span> struct from the package <span v-highlightjs><code class="go" v-pre style="display: inline">http</code></span> has a field named <span v-highlightjs><code class="go" v-pre style="display: inline">Handler</code></span>. Here is an extract of the file <strong>net/http/server.go</strong> from the standard library.</p>
<pre v-highlightjs><code class="go" v-pre >package http
//..
type Server struct {
    Addr    string  // TCP address to listen on, &quot;:http&quot; if empty
    Handler Handler // handler to invoke, http.DefaultServeMux if nil
    //...
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Handler</code></span> (from package <span v-highlightjs><code class="go" v-pre style="display: inline">http</code></span>) is an interface :</p>
<pre v-highlightjs><code class="go" v-pre >package http
//...
type Handler interface {
    ServeHTTP(ResponseWriter, *Request)
}</code></pre>
<div id="create-your-own-http.handler-implementation" class="anchor"></div>
<h4 data-number="13.0.0.3"><span class="header-section-number">13.0.0.3</span> Create your own http.Handler implementation <a href="#create-your-own-http.handler-implementation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We should create our own type that implements this interface to define an HTTP handler :</p>
<pre v-highlightjs><code class="go" v-pre >type myHandler struct {
}

func (h *myHandler)ServeHTTP(w http.ResponseWriter, r *http.Request) {
    // TODO
}</code></pre>
<p>Here I created a type struct named <span v-highlightjs><code class="go" v-pre style="display: inline">myHandler</code></span>. On this type, I defined a method named <span v-highlightjs><code class="go" v-pre style="display: inline">ServeHTTP</code></span>. This method takes two parameters named <span v-highlightjs><code class="go" v-pre style="display: inline">w</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">r</code></span>. <span v-highlightjs><code class="go" v-pre style="display: inline">w</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">http.ResponseWriter</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">r</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*http.Request</code></span>.</p>
<p>What are those types? Let’s jump to the documentation.</p>
<div id="http.responsewriter" class="anchor"></div>
<h4 data-number="13.0.0.4"><span class="header-section-number">13.0.0.4</span> http.ResponseWriter <a href="#http.responsewriter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>“An HTTP handler uses a ResponseWriter interface to construct an HTTP response.” We can see that’s a type <strong>interface</strong> :</p>
<pre v-highlightjs><code class="go" v-pre >package http
//...
type ResponseWriter interface {
    // Header returns the header map that will be sent by
    // WriteHeader. The Header map also is the mechanism with which
    // Handlers can set HTTP trailers.
    //...
    Header() Header

    // Write writes the data to the connection as part of an HTTP reply.
    //...
    Write([]byte) (int, error)

    // WriteHeader sends an HTTP response header with the provided
    // status code.
    //...
    WriteHeader(statusCode int)
}</code></pre>
<p>When the server receives a request, it will pass to our function an implementation of this interface. It means that we will have the ability to use three methods :</p>
<pre v-highlightjs><code class="go" v-pre >Header</code></pre>
<ul>
<li>This method will return an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">http.Header</code></span> which is a map of headers.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >Write</code></pre>
<ul>
<li>With this method, we will be able to write content to the response body.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >WriteHeader</code></pre>
<ul>
<li>With this method, we can send a specific status code.</li>
</ul>
<div id="http.request" class="anchor"></div>
<h4 data-number="13.0.0.5"><span class="header-section-number">13.0.0.5</span> http.Request <a href="#http.request"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">http.Request</code></span> is a type struct that lists all the elements of the received request. This type struct has 10+ exported fields. Later, we will see that this struct is also used for building HTTP clients! The three more important are :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">URL</code></span> which is in the case of a server the <strong>URI.</strong></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Body</code></span> : which contains the message body.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Header</code></span> : a map containing headers</p></li>
</ul>
<div id="implementation-of-serverhttp" class="anchor"></div>
<h4 data-number="13.0.0.6"><span class="header-section-number">13.0.0.6</span> Implementation of ServerHTTP <a href="#implementation-of-serverhttp"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Here is our handler :</p>
<pre v-highlightjs><code class="go" v-pre >type myHandler struct {
}

func (h *myHandler)ServeHTTP(w http.ResponseWriter, r *http.Request) {
    // TODO
}</code></pre>
<p>The method ServeHTTP “should write reply headers and data to the ResponseWriter and then return. Returning signals that the request is finished<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a>”.</p>
<p>We will call <span v-highlightjs><code class="go" v-pre style="display: inline">w.Write</code></span> to write to the response body our HTML Page. The function Write has the following signature :</p>
<pre v-highlightjs><code class="go" v-pre >Write([]byte) (int, error)</code></pre>
<p>It accepts a slice of bytes and returns an integer or an error. The integer returned is the number of bytes written to the response body.</p>
<p>Let’s see how we will do that :</p>
<pre v-highlightjs><code class="go" v-pre >func (h *myHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
    toSend := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;/head&gt;&lt;body&gt;Hello&lt;/hello&gt;&lt;/html&gt;&quot;)
    _, err := w.Write(toSend)
    if err != nil {
        log.Printf(&quot;error while writing on the body %s&quot;, err)
    }
}</code></pre>
<ul>
<li><p>We create a initialize a variable <span v-highlightjs><code class="go" v-pre style="display: inline">toSend</code></span>. It is a slice of bytes containing our HTML document. Note that we have deleted the line breaks. The web browser ignores the line breaks; it’s just there to help the developer write the HTML document.</p></li>
<li><p>Then we pass this variable to <span v-highlightjs><code class="go" v-pre style="display: inline">w.Write</code></span></p>
<ul>
<li><p>We ignore the number of bytes written (with <span v-highlightjs><code class="go" v-pre style="display: inline">_</code></span>)</p></li>
<li><p>A new variable <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span> is created that will hold the error.</p></li>
</ul></li>
<li><p>Usually, when we have an error, we should test if it’s not <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p></li>
<li><p>If the error is not nil, we log the error</p></li>
</ul>
<p>When the function returns, the request is finished.</p>
<div id="add-the-handler-to-the-server" class="anchor"></div>
<h4 data-number="13.0.0.7"><span class="header-section-number">13.0.0.7</span> Add the handler to the server <a href="#add-the-handler-to-the-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We need to register the Handler :</p>
<pre v-highlightjs><code class="go" v-pre >myServer := &amp;http.Server{
    // set the server address
    Addr: &quot;127.0.0.1:8080&quot;,
    // define some specific configuration
    ReadTimeout:  10 * time.Second,
    WriteTimeout: 10 * time.Second,
    Handler:      &amp;myHandler{},
}</code></pre>
<p>Our server will now launch <span v-highlightjs><code class="go" v-pre style="display: inline">myHandler.ServeHTTP</code></span> for each request received!</p>
<div id="complete-code" class="anchor"></div>
<h4 data-number="13.0.0.8"><span class="header-section-number">13.0.0.8</span> Complete code <a href="#complete-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// basic-http-server/serve-html/main.go
package main

import (
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    // create a server
    myServer := &amp;http.Server{
        // set the server address
        Addr: &quot;127.0.0.1:8080&quot;,
        // define some specific configuration
        ReadTimeout:  10 * time.Second,
        WriteTimeout: 10 * time.Second,
        // register our handler
        Handler: &amp;myHandler{},
    }

    // launch the server
    log.Fatal(myServer.ListenAndServe())
}

type myHandler struct {
}

// function executed for each HTTP request received
func (h *myHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
    toSend := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;/head&gt;&lt;body&gt;Hello&lt;/hello&gt;&lt;/html&gt;&quot;)
    _, err := w.Write(toSend)
    if err != nil {
        log.Printf(&quot;error while writing on the body %s&quot;, err)
    }
}</code></pre>
<p>Now let’s build it, and run it!</p>
<pre v-highlightjs><code class="go" v-pre >$ go build main.go
$ ./main</code></pre>
<div id="test-with-a-web-browser" class="anchor"></div>
<h4 data-number="13.0.0.9"><span class="header-section-number">13.0.0.9</span> Test with a web browser <a href="#test-with-a-web-browser"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<figure>
<b-img :src="require('@/assets/images/send_html_server.png')" alt="Response"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Response</figcaption>
</figure>
<div id="test-with-curl" class="anchor"></div>
<h4 data-number="13.0.0.10"><span class="header-section-number">13.0.0.10</span> Test with curl <a href="#test-with-curl"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >$ curl 127.0.0.1:8080 --verbose
* Rebuilt URL to: 127.0.0.1:8080/
*   Trying 127.0.0.1...
* TCP_NODELAY set
* Connected to 127.0.0.1 (127.0.0.1) port 8080 (#0)
&gt; GET / HTTP/1.1
&gt; Host: 127.0.0.1:8080
&gt; User-Agent: curl/7.54.0
&gt; Accept: */*
&gt;
&lt; HTTP/1.1 200 OK
&lt; Date: Sat, 04 Apr 2020 19:07:55 GMT
&lt; Content-Length: 45
&lt; Content-Type: text/html; charset=utf-8
&lt;
* Connection #0 to host 127.0.0.1 left intact
&lt;html&gt;&lt;head&gt;&lt;/head&gt;&lt;body&gt;Hello&lt;/hello&gt;&lt;/html&gt;</code></pre>
<p>Hooray!</p>
<div id="common-error-address-already-in-use" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Common error: address already in use <a href="#common-error-address-already-in-use"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When you try to launch your web server, you might have this error. It means that another program already uses the port you have chosen. To solve it check that you have not forgotten to stop another server running on your machine.</p>
<div id="macos-linux" class="anchor"></div>
<h3 data-number="14.0.1"><span class="header-section-number">14.0.1</span> MacOs / Linux <a href="#macos-linux"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You can check which program is listening on port 8084 with this command :</p>
<pre v-highlightjs><code class="go" v-pre >$ sudo lsof -i 8084</code></pre>
<p>On figure <a href="#fig:lsof-output" data-reference-type="ref" data-reference="fig:lsof-output">11</a> you can see the output of the command.</p>
<figure>
<b-img :src="require('@/assets/images/lsof_output.png')" alt="lsof output[fig:lsof-output]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">lsof output<span id="fig:lsof-output" label="fig:lsof-output">[fig:lsof-output]</span></figcaption>
</figure>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">lsof</code></span> means “list open files”.</p>
<ul>
<li><p>The column <span v-highlightjs><code class="go" v-pre style="display: inline">COMMAND</code></span> allow you to identify the command name</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">PID</code></span> is the process id (10206)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">USER</code></span> : is the user that launched the program (here me)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">TYPE</code></span> : inform you about the type of the open file. (here IPv4)</p></li>
</ul>
<p>From the command line, you can directly kill the process with the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ kill 10206</code></pre>
<p>The OS will terminate the program.</p>
<div id="windows" class="anchor"></div>
<h4 data-number="14.0.1.1"><span class="header-section-number">14.0.1.1</span> Windows <a href="#windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The following command will give you the process id of the program listening on port 8084:</p>
<pre v-highlightjs><code class="go" v-pre >$ netstat -ano | find &quot;8084&quot;
TCP 0.0.0.0:8084 0.0.0.0:0 LISTENING 10206</code></pre>
<p><strong>10206</strong> is the process id of the program listening on 0.0.0.0:8084.</p>
<p>To kill this process, you can use the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ taskkill /F /PID 10206</code></pre>
<div id="common-error-too-many-open-files" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Common error: too many open files <a href="#common-error-too-many-open-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>What are “open files” ? There are two main types of open files :</p>
<ul>
<li><p>Files that are opened and read or write by a system user.</p></li>
<li><p>Open sockets: an open TCP connection, for example.</p></li>
</ul>
<p>Any OS can handle a limited number of open files.</p>
<p>We will see some commands to get this number on your system :</p>
<div id="macos-linux-1" class="anchor"></div>
<h3 data-number="15.0.1"><span class="header-section-number">15.0.1</span> MacOs / Linux <a href="#macos-linux-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >$ lsof | wc -l</code></pre>
<ul>
<li><p>We call <span v-highlightjs><code class="go" v-pre style="display: inline">lsof</code></span> to get the list of open files</p></li>
<li><p>Then we count the lines returned by this command (<span v-highlightjs><code class="go" v-pre style="display: inline">wc -l</code></span>)</p></li>
</ul>
<div id="windows-1" class="anchor"></div>
<h3 data-number="15.0.2"><span class="header-section-number">15.0.2</span> Windows <a href="#windows-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The tool Process Explorer program is a good starting point to see the list of process runnings and open files on your system. Here is a link to download it:</p>
<p>https://docs.microsoft.com/sysinternals/downloads/process-explorer.</p>
<div id="possible-root-cause" class="anchor"></div>
<h3 data-number="15.0.3"><span class="header-section-number">15.0.3</span> Possible root cause <a href="#possible-root-cause"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When you open a file, you should not forget to close it when you don’t need it. Here is an example http handler to demonstrate it :</p>
<pre v-highlightjs><code class="go" v-pre >func (h *myHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
    _, err := os.Open(&quot;/tmp/test&quot;)
    if err != nil {
        log.Println(&quot;error file open &quot;, err)
    } else {
        log.Println(&quot;file opened&quot;)
    }
}</code></pre>
<p>For each HTTP request, we will open the file <span v-highlightjs><code class="go" v-pre style="display: inline">/tmp/test</code></span>. Files will not close automatically when the response is sent!</p>
<p>Let’s test this handler with a standard server :</p>
<pre v-highlightjs><code class="go" v-pre >func main() {
    // create a server
    myServer := &amp;http.Server{
        // set the server address
        Addr: &quot;127.0.0.1:8084&quot;,
        // define some specific configuration
        ReadTimeout:  10 * time.Second,
        WriteTimeout: 10 * time.Second,
        Handler:      &amp;myHandler{},
    }

    // launch the server
    log.Fatal(myServer.ListenAndServe())
}</code></pre>
<p>Then, we build it and launch it :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o myProg main.go
$ ./myBuggyServer</code></pre>
<p>Let’s see now how many files were opened by this program. We first have to get its process id :</p>
<pre v-highlightjs><code class="go" v-pre >$ lsof -i :8084
myProg  12138 maximilienandile    3u  IPv4 0x1f9c2813af8142d1      0t0 TCP localhost:8084 (LISTEN)</code></pre>
<p>The process id is 12138. Let’s count the number of files opened :</p>
<pre v-highlightjs><code class="go" v-pre >$ lsof -p 12458 | wc -l
10</code></pre>
<p>Ten files opened! Now let’s launch four requests to the server (with the cURL command line) :</p>
<pre v-highlightjs><code class="go" v-pre >$ curl 127.0.0.1:8084
$ curl 127.0.0.1:8084
$ curl 127.0.0.1:8084
$ curl 127.0.0.1:8084</code></pre>
<p>Let’s check now how many open files the process has (the process id has not changed)</p>
<pre v-highlightjs><code class="go" v-pre >$ lsof -p 12458 | wc -l
14</code></pre>
<p>The server will use too many resources. The garbage collector will close those open files for you. It’s still dangerous because your Go script might overflow the limit of your system’s open files before the garbage collection.</p>
<div id="application-ab-testing-server" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Application: A/B testing server <a href="#application-ab-testing-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="mission" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> Mission <a href="#mission"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Your client has two design ideas (A and B) for the hotel website’s homepage. He cannot choose between the two designs. He asks you if it’s possible to test the two design versions.</p>
<p>The winning design is the one that will generate more phone calls.</p>
<p>Your mission: build an http server that :</p>
<ul>
<li><p>listen on : IP 127.0.0.1 / Port 9899</p></li>
<li><p>For each HTTP request received, send either design A or design B :</p>
<ul>
<li><p>When the current number of minutes is odd, serve A</p></li>
<li><p>Else serve B</p></li>
</ul></li>
</ul>
<p>Here is the design <strong>A :</strong></p>
<pre v-highlightjs><code class="go" v-pre >&lt;html&gt;
&lt;head&gt;
    &lt;title&gt;The Golang Hotel&lt;/title&gt;
&lt;/head&gt;
&lt;body&gt;
  &lt;p&gt;The Golang Hotel is a relaxing place !&lt;/p&gt;
  &lt;p&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567891&lt;/strong&gt;&lt;/p&gt;
&lt;/body&gt;
&lt;/html&gt;</code></pre>
<p>And the design <strong>B</strong> :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;html&gt;
&lt;head&gt;
  &lt;title&gt;The Golang Hotel&lt;/title&gt;
&lt;/head&gt;
&lt;body&gt;
  &lt;h2&gt;The Golang Hotel is a relaxing place !&lt;/h2&gt;
  &lt;h5&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567892&lt;/strong&gt;&lt;/h5&gt;
&lt;/body&gt;
&lt;/html&gt;</code></pre>
<div id="solution" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="go.mod-file" class="anchor"></div>
<h4 data-number="16.2.0.1"><span class="header-section-number">16.2.0.1</span> go.mod file <a href="#go.mod-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The first step is to initialize a project with a go.mod file :</p>
<pre v-highlightjs><code class="go" v-pre >module maximilien-andile.com/webserver/application1

go 1.13</code></pre>
<p>You can also generate this file with the command <span v-highlightjs><code class="go" v-pre style="display: inline">go mod init</code></span></p>
<pre v-highlightjs><code class="go" v-pre >$ go mod init maximilien-andile.com/webserver/application1
go: creating new go.mod: module maximilien-andile.com/webserver/application1</code></pre>
<div id="http.server-struct" class="anchor"></div>
<h4 data-number="16.2.0.2"><span class="header-section-number">16.2.0.2</span> http.Server struct <a href="#http.server-struct"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Then we need to create a new http.Server :</p>
<pre v-highlightjs><code class="go" v-pre >abTestingServer := &amp;http.Server{
    Addr: &quot;127.0.0.1:9899&quot;,
    ReadTimeout:  10 * time.Second,
    WriteTimeout: 10 * time.Second,
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">abTestingServer</code></span> is a pointer to this struct.</p>
<div id="handler-skeleton" class="anchor"></div>
<h4 data-number="16.2.0.3"><span class="header-section-number">16.2.0.3</span> Handler skeleton <a href="#handler-skeleton"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We create a struct type that will implement the <span v-highlightjs><code class="go" v-pre style="display: inline">http.Handler</code></span> interface. For memory, this is the http.Handler interface :</p>
<pre v-highlightjs><code class="go" v-pre >type Handler interface {
    ServeHTTP(ResponseWriter, *Request)
}</code></pre>
<p>We must implement the ServerHTTP method. Here is our <span v-highlightjs><code class="go" v-pre style="display: inline">http.Handler</code></span> implementation :</p>
<pre v-highlightjs><code class="go" v-pre >type AbHandler struct {
}

func (h *AbHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
}</code></pre>
<p>We can then set the <span v-highlightjs><code class="go" v-pre style="display: inline">Handler</code></span> field in <span v-highlightjs><code class="go" v-pre style="display: inline">abTestingServer</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >abTestingServer := &amp;http.Server{
    Addr: &quot;127.0.0.1:8084&quot;,
    ReadTimeout:  10 * time.Second,
    WriteTimeout: 10 * time.Second,
    Handler:&amp;AbHandler{},
}</code></pre>
<p>What is <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;AbHandler{}</code></span> ? Let’s decompose it : <span v-highlightjs><code class="go" v-pre style="display: inline">AbHandler{}</code></span> will create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">AbHandler</code></span></p>
<ul>
<li>With the sign <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span> we will take the address of this variable.</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">AbHandler{}</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*AbHandler</code></span></p>
<div id="handler-implementation-loading-html-designs" class="anchor"></div>
<h4 data-number="16.2.0.4"><span class="header-section-number">16.2.0.4</span> Handler implementation : loading HTML designs <a href="#handler-implementation-loading-html-designs"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The first thing to do is to load our two designs :</p>
<pre v-highlightjs><code class="go" v-pre >func (h *AbHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
    // load design A
    designA := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;p&gt;The Golang Hotel is a relaxing place !&lt;/p&gt;&lt;p&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567891&lt;/strong&gt;&lt;/p&gt;&lt;/body&gt;&lt;/html&gt;&quot;)

    // load design B
    designB := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;h2&gt;The Golang Hotel is a relaxing place !&lt;/h2&gt;&lt;h5&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567892&lt;/strong&gt;&lt;/h5&gt;&lt;/body&gt;&lt;/html&gt;&quot;)
}</code></pre>
<p>We have created two variables : <span v-highlightjs><code class="go" v-pre style="display: inline">designA</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">designB</code></span>. They are slices of bytes. Note that the HTML content has been minified: new lines and useless spaces have been removed. The HTML version of each design.</p>
<div id="handler-implementation-serve-two-html-page-alternatively" class="anchor"></div>
<h4 data-number="16.2.0.5"><span class="header-section-number">16.2.0.5</span> Handler implementation: Serve two HTML page alternatively <a href="#handler-implementation-serve-two-html-page-alternatively"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Now in the handler, we can implement the response logic.</p>
<pre v-highlightjs><code class="go" v-pre >func (h *AbHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {

    designA := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;p&gt;The Golang Hotel is a relaxing place !&lt;/p&gt;&lt;p&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567891&lt;/strong&gt;&lt;/p&gt;&lt;/body&gt;&lt;/html&gt;&quot;)

    designB := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;h2&gt;The Golang Hotel is a relaxing place !&lt;/h2&gt;&lt;h5&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567892&lt;/strong&gt;&lt;/h5&gt;&lt;/body&gt;&lt;/html&gt;&quot;)

    minutes := time.Now().Minute()

    if minutes%2 == 0 {
        // even
        log.Println(&quot;serving B&quot;)
        _, err := w.Write(designB)
        if err != nil {
            log.Print(&quot;impossible to serve design A&quot;, err)
        }
    } else {
        // odd
        log.Println(&quot;serving A&quot;)
        _, err := w.Write(designA)
        if err != nil {
            log.Print(&quot;impossible to serve design B&quot;, err)
        }
    }
}</code></pre>
<p>For each request, we will get the current minute with <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now().Minute()</code></span></p>
<ul>
<li><p>If this number is even (<span v-highlightjs><code class="go" v-pre style="display: inline">minutes % 2 == 0</code></span>), then we will write the design B on the response writer</p></li>
<li><p>Else, design A is sent</p></li>
</ul>
<p>One thing is missing: we need to start the server :</p>
<pre v-highlightjs><code class="go" v-pre >log.Fatal(abTestingServer.ListenAndServe())</code></pre>
<div id="complete-code-1" class="anchor"></div>
<h4 data-number="16.2.0.6"><span class="header-section-number">16.2.0.6</span> Complete code <a href="#complete-code-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// basic-http-server/ab-testing/main.go
package main

import (
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    // create a server
    abTestingServer := &amp;http.Server{
        // set the server address
        Addr: &quot;127.0.0.1:9899&quot;,
        // define some specific configuration
        ReadTimeout:  10 * time.Second,
        WriteTimeout: 10 * time.Second,
        Handler:      &amp;AbHandler{},
    }
    log.Fatal(abTestingServer.ListenAndServe())
}

type AbHandler struct {
}

func (h *AbHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {

    designA := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;p&gt;The Golang Hotel is a relaxing place !&lt;/p&gt;&lt;p&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567891&lt;/strong&gt;&lt;/p&gt;&lt;/body&gt;&lt;/html&gt;&quot;)

    designB := []byte(&quot;&lt;html&gt;&lt;head&gt;&lt;title&gt;The Golang Hotel&lt;/title&gt;&lt;/head&gt;&lt;body&gt;&lt;h2&gt;The Golang Hotel is a relaxing place !&lt;/h2&gt;&lt;h5&gt;We offer 20% discount if you call this number : &lt;strong&gt;1234567892&lt;/strong&gt;&lt;/h5&gt;&lt;/body&gt;&lt;/html&gt;&quot;)

    minutes := time.Now().Minute()

    if minutes%2 == 0 {
        // even
        log.Println(&quot;serving B&quot;)
        _, err := w.Write(designB)
        if err != nil {
            log.Print(&quot;impossible to serve design A&quot;, err)
        }
    } else {
        // odd
        log.Println(&quot;serving A&quot;)
        _, err := w.Write(designA)
        if err != nil {
            log.Print(&quot;impossible to serve design B&quot;, err)
        }
    }
}</code></pre>
<div id="test-of-the-server" class="anchor"></div>
<h4 data-number="16.2.0.7"><span class="header-section-number">16.2.0.7</span> Test of the server <a href="#test-of-the-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To test the server, we first need to compile and run our application :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o abServer main.go
$ ./abServer</code></pre>
<p>Open a web browser and type <span v-highlightjs><code class="go" v-pre style="display: inline">http://localhost:9899/</code></span> in the address bar.</p>
<figure>
<b-img :src="require('@/assets/images/ab_testing_server.png')" alt="A/B testing server"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A/B testing server</figcaption>
</figure>
<div id="possible-improvements" class="anchor"></div>
<h2 data-number="16.3"><span class="header-section-number">16.3</span> Possible improvements <a href="#possible-improvements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>We create two slices of bytes for each request to store the design. This is useless.</p>
<ul>
<li>We can store the two designs into two handler fields initialized when the server boot (in the main function)</li>
</ul></li>
<li><p>What if we want to store the two HTML on files? If we want to change one design (add some colors, add an image), we have to recompile the Go program. If files are stored into files, we can easily change the design without recompiling the server.</p></li>
<li><p>In the program, each URI will return the same two HTML pages. What if we want to define a specific path for this page. For instance : <span v-highlightjs><code class="go" v-pre style="display: inline">/special-offer-summer</code></span>.</p>
<ul>
<li>We can achieve it with a <strong>router</strong> (that will trigger a specific handler for each specific route). We will see later how to do that.</li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="17.1"><span class="header-section-number">17.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Which package from the standard library can you use to build an HTTP web server?</p></li>
<li><p>True or False. An HTTP request is sent from the server to the client.</p></li>
<li><p>How to create an HTTP web server?</p></li>
<li><p>Inside a handler method (<span v-highlightjs><code class="go" v-pre style="display: inline">ServeHTTP(w http.ResponseWriter, r *http.Request)</code></span>) can you get the value of a request header? How?</p></li>
<li><p>Can you give the signification of the HTTP status code 200, 500, and 400?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="17.2"><span class="header-section-number">17.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Which package from the standard library can you use to build an HTTP web server?</p>
<pre v-highlightjs><code class="go" v-pre >net/http</code></pre></li>
<li><p>True or False. An HTTP request is sent from the server to the client.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The client sends the request to the server</p></li>
<li><p>The server will answer with an HTTP response</p></li>
</ol></li>
<li><p>How to create an http web server?</p>
<ol type="1">
<li><p>Create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">*http.Server</code></span></p>
<ol type="1">
<li><p>Set the listening address</p></li>
<li><p>Set the read and write timeout (to avoid defaults)</p></li>
</ol></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >myServer := &amp;http.Server{
    // set the server address
    Addr: &quot;127.0.0.1:8080&quot;,
    // define some specific configuration
    ReadTimeout:  10 * time.Second,
    WriteTimeout: 10 * time.Second,
}</code></pre>
<ol type="1">
<li><p>Create a type <span v-highlightjs><code class="go" v-pre style="display: inline">MyHandler</code></span> that implements the <span v-highlightjs><code class="go" v-pre style="display: inline">http.Handler</code></span> interface.</p>
<ol type="1">
<li>Implement the method <span v-highlightjs><code class="go" v-pre style="display: inline">ServeHTTP(http.ResponseWriter, *http.Request)</code></span></li>
</ol></li>
<li><p>Set the field <span v-highlightjs><code class="go" v-pre style="display: inline">Handler</code></span> of <span v-highlightjs><code class="go" v-pre style="display: inline">myServer</code></span> to a pointer to <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;MyHandler{}</code></span></p></li>
<li><p>Start the server by calling the method <span v-highlightjs><code class="go" v-pre style="display: inline">ListenAndServe()</code></span></p></li>
</ol></li>
<li><p>Inside a handler method (<span v-highlightjs><code class="go" v-pre style="display: inline">ServeHTTP(w http.ResponseWriter, r *http.Request)</code></span>) can you get the value of a request header? How?</p>
<ol type="1">
<li>Yes, by calling <span v-highlightjs><code class="go" v-pre style="display: inline">r.Header.Get("Name-Of-The-Header")</code></span></li>
</ol></li>
<li><p>Can you give the signification of the HTTP status code 200, 500, and 400?</p>
<ol type="1">
<li><p>200 = “OK”</p></li>
<li><p>500 = “Internal Server Error” the server had an unexpected error</p></li>
<li><p>400 = “Bad Request” the request you have sent is malformed.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An HTTP Server is a program that will handle HTTP requests sent by clients</p></li>
<li><p>Clients can be web browsers (but not only, they can be other programs)</p></li>
<li><p>HTTP is a communication protocol</p></li>
<li><p>We can send an HTTP request to a server identified by an IP.</p></li>
<li><p>We can also send an HTTP request to a domain name that is mapped to one or several servers by the Domain Name System (DNS)</p></li>
<li><p>HTTP responses are composed of a status code (an unsigned integer) generated by the server to inform the client about its request status.</p>
<ul>
<li><p>200 = “OK”</p></li>
<li><p>500 = “Internal Server Error” the server had an unexpected error</p></li>
<li><p>400 = “Bad Request” the request you have sent is malformed.</p></li>
</ul></li>
<li><p>You can build an HTTP server with the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">net/http</code></span></p>
<ul>
<li>Here is a sample HTTP server</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// basic-http-server/sample/main.go
package main

import (
    &quot;log&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    // create a server
    abTestingServer := http.Server{
        // set the server address
        Addr: &quot;127.0.0.1:9899&quot;,
        // define some specific configuration
        ReadTimeout:  10 * time.Second,
        WriteTimeout: 10 * time.Second,
        Handler:      &amp;MyHandler{},
    }
    log.Fatal(abTestingServer.ListenAndServe())
}

type MyHandler struct {
}

func (m *MyHandler) ServeHTTP(w http.ResponseWriter, r *http.Request) {
    // TODO
}</code></pre>
<ul>
<li><p>the parameter <span v-highlightjs><code class="go" v-pre style="display: inline">w</code></span> allow you to write the response</p></li>
<li><p>the parameter <span v-highlightjs><code class="go" v-pre style="display: inline">r</code></span> allow you to access the request</p></li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Source: https://en.wikipedia.org/wiki/IP_address<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>RFC 2616 section 5.3<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>RFC 2616 : https://www.w3.org/Protocols/rfc2616/rfc2616-sec6.html#sec6<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>https://tools.ietf.org/html/rfc7231#section-6.6.1<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>Documentation http package<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap26BasicHttpServer"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap25JsonAndXml'}">
									<p><u><small>Previous</small></u></p>
									<p><small>JSON and XML</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap27EnumIotaAndBitmask'}">
									<p><u><small>Next</small></u></p>
									<p><small>Enum, Iota & Bitmask</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Basic HTTP Server - Practical Go Lessons"
const description = "HTML Basics, creation of a basic HTTP server in Go with the standard library"

export default {
  name: "Chap26BasicHttpServer",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
